import React, { Fragment, useEffect, useState } from 'react';
import PageTitle from '../../components/pagetitle/PageTitle';
import { useParams } from 'react-router-dom'
import Navbar from '../../components/Navbar';
import Scrollbar from '../../components/scrollbar'
import { connect } from "react-redux";
import api from "../../api";
import Footer from '../../components/footer';
import Logo from '../../images/logo4.png'
import Rooms from './rooms';
import RoomDetails from './RoomDetails';
import Newslatter from '../../components/Newslatter/Newslatter';
import { Helmet } from 'react-helmet';


const RoomSinglePage = (props) => {
    const { id } = useParams()


    const productsArray = api();
    const Allproduct = productsArray

    const [activeRoom, setActiveRoom] = useState(id)
    const [roomData, setRoomData] = useState(null)

    useEffect(() => {
        setRoomData(Allproduct.find(Allproduct => Allproduct.id === Number(id)))
    }, []);

    const item = roomData;

    return (
        <Fragment>
            <Helmet>
                <title>{item?.title ? item?.title : ''} | Motel Essence</title>
                <meta
                  name="description"
                  content="Ven y disfruta de momentos inolvidables con tu pareja en uno de los mejores moteles de cochabamba y vive la experiencia de nuestras habitaciones temáticas"
                />
                <meta name="keywords" content="cochabamba, alojamiento, motel, habitaciones cercanas, habitaciones baratas" />
                <meta name="robots" content="index, follow"/>
                <meta name="googlebot" content="index, follow"/>
                <link rel="canonical" href={`${process.env.REACT_APP_FRONTENDURL}`} />
                <meta property="og:title" content="Motel Essence | Cochabamba" />
                <meta property="og:description" content="Ven y disfruta de momentos inolvidables con tu pareja en uno de los mejores moteles de cochabamba y vive la experiencia de nuestras habitaciones temáticas" />
                <meta property="og:image" content={item?.heroBackground ? `${process.env.REACT_APP_FRONTENDURL}${item?.heroBackground}` : ''} />
                <meta property="og:url" content={`${process.env.REACT_APP_FRONTENDURL}`} />
            </Helmet>
            <Navbar hclass={'wpo-header-style-3'} Logo={Logo} />
            <PageTitle pageTitle={item ? item.title : null} heroImage={item ? item.heroBackground : null} pagesub={'Room'} />
            <div className="room-details-section">
                {item ?
                    <div className="room-details-inner">
                        <RoomDetails 
                            roomData = {roomData}
                            isPromo={false}
                            promo={null}
                            setActiveRoom={setActiveRoom}
                            activeRoom={activeRoom}
                        />
                        <Rooms roomData={item}/>
                    </div>
                : null}
            </div>
            <Newslatter nClass={'section-bg'}/>
            <Footer />
            <Scrollbar />
        </Fragment>
    )
};

const mapStateToProps = state => {
    return {
        products: state.data.products,
    }
};

export default connect(mapStateToProps)(RoomSinglePage);
