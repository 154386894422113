import React from 'react';
import { BrowserRouter, Routes, Route, Navigate, } from "react-router-dom";
import Homepage from '../HomePage'
import Homepage2 from '../HomePage2'
import HomePage3 from '../HomePage3';
import AboutPage from '../AboutPage/AboutPage';
import CartPage from '../CartPage';
import CheckoutPage from '../CheckoutPage';
import DestinationPage from '../DestinationPage/DestinationPage';
import DestinationSinglePage from '../DestinationSinglePage/DestinationSinglePage';
import OrderRecived from '../OrderRecived';
import RoomPage from '../RoomPage';
import RoomSinglePage from '../RoomSinglePage';
import SearchResults from '../SearchResults/SearchResults';
import ServicePage from '../ServicePage/ServicePage';
import ServicePageS2 from '../ServicePageS2/ServicePageS2';
import ServiceSinglePage from '../ServiceSinglePage/ServiceSinglePage';
import BlogPage from '../BlogPage'
import BlogPageLeft from '../BlogPageLeft'
import BlogPageFullwidth from '../BlogPageFullwidth'
import BlogDetails from '../BlogDetails'
import BlogDetailsFull from '../BlogDetailsFull'
import BlogDetailsLeftSiide from '../BlogDetailsLeftSiide'
import LoginPage from '../LoginPage'
import SignUpPage from '../SignUpPage'
import ForgotPassword from '../ForgotPassword'
import PricingPage from '../PricingPage/PricingPage';
import ErrorPage from '../ErrorPage/ErrorPage';
import ContactPage from '../ContactPage/ContactPage';
import ThanksPage from '../ThanksPage';
import PromoSinglePage from '../PromoSinglePage';
import PromosPage from '../PromosPage';

const AllRoute = () => {

  return (
    <div className="App">
      <BrowserRouter>
        <Routes>
          {/* Active routes */}
          <Route path='/' element={<Homepage />} />
          <Route path='habitaciones/:id' element={<RoomSinglePage />} />
          <Route path='promos/:id' element={<PromoSinglePage />} />
          <Route path='contacto' element={<ContactPage />} />
          <Route path='gracias' element={<ThanksPage />} />
          <Route path="*" element={<Navigate to="/" replace />} />
          {/* Not use routes */}
          <Route path='habitaciones' element={<SearchResults />} />
          <Route path='promociones' element={<PromosPage />} />
        </Routes>
      </BrowserRouter>

    </div>
  );
}

export default AllRoute;
