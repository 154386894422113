import React from 'react'
import { Link } from 'react-router-dom'
import Logo from '../../images/logo.png'
import Services from '../../api/service'
import { WhatssAppIcon } from '../icons/Whatsapp'


const Footer = (props) => {

    const ClickHandler = () => {
        window.scrollTo(10, 0);
    }

    return (
        <footer className="wpo-site-footer">
            <div className="wpo-upper-footer">
                <div className="container">
                    <div className="row">
                        <div className="col col-lg-4 col-md-6 col-sm-12 col-12">
                            <div className="widget about-widget">
                                <div className="logo widget-title">
                                    <img src={Logo} alt="footer" />
                                </div>
                                {/* <p>Management consulting includes a broad range of activities, and the many firms and
                                    their members often define these practices.</p> */}
                                <ul>
                                    <li>
                                        <a href='https://www.facebook.com/essencecbba' target={'_blank'} rel="noreferrer">
                                            <i className="ti-facebook"></i>
                                        </a>
                                    </li>
                                    <li>
                                        <a href='https://www.instagram.com/motel.essence/' target={'_blank'} rel="noreferrer">
                                            <i className="ti-instagram"></i>
                                        </a>
                                    </li>
                                    <li>
                                        <a href="https://wa.link/0igtz5" target={'_blank'} rel='noreferrer'>
                                            <WhatssAppIcon/>
                                        </a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div className="col col-lg-4 col-md-6 col-sm-12 col-12">
                            <div className="widget link-widget">
                                <div className="widget-title">
                                    <h3>Páginas</h3>
                                </div>
                                <ul>
                                    <li><Link onClick={ClickHandler} to="/contacto">Contacto</Link></li>
                                    <li><Link onClick={ClickHandler} to="/destination">Habitaciones</Link></li>
                                    <li><Link onClick={ClickHandler} to="/promociones">Promociones</Link></li>
                                </ul>
                            </div>
                        </div>

                        <div className="col col-lg-4 col-md-6 col-sm-12 col-12">
                            <div className="widget wpo-service-link-widget">
                                <div className="widget-title">
                                    <h3>Contacto </h3>
                                </div>
                                <div className="contact-ft">
                                    <ul>
                                        <a href='https://goo.gl/maps/WA4v6Xfvm1f7e97M6' target={'_blank'} rel={'noreferrer'}><li><i className="fi flaticon-placeholder"></i>Calle José María Santivañez esq. Daniel Campos, zona Sausalito</li></a>
                                        <a href='tel:77984205' target={'_blank'} rel={'noreferrer'}><li><i className="fi flaticon-phone-call"></i>77984205</li></a>
                                        <a href='tel:44394894' target={'_blank'} rel={'noreferrer'}><li><i className="fi flaticon-phone-call"></i>4394894</li></a>
                                        <a href='mailto:reservas@motel-essence.com' target={'_blank'} rel={'noreferrer'}><li><i className="fi flaticon-send"></i>reservas@motel-essence.com</li></a>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="wpo-lower-footer">
                <div className="container">
                    <div className="row">
                        <div className="col col-xs-12">
                            <p className="copyright"> Desarrollado por Vogel 2022</p>
                        </div>
                    </div>
                </div>
            </div>
        </footer>
    )
}

export default Footer;