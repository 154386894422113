import * as types from "./type";
import { toast } from "react-toastify";
import api from "../../api";

export const addRoomToCart = (props) => (dispatch, getState) => {
    const roomExtras = {
        id: props.roomId,
        promoId: props.promoId,
        date: props.date,
        hourReservation: props.hourReservation,
        totalHours: props.totalHours,
        totalPrice: props.totalPrice,
        jacuzzi: props.jacuzzi,
        isPromo: props.isPromo,
        promoName: props.promoName
    }
    const product = api().find(prod => prod.id === parseInt(props.roomId))
    const cart = {
        ...product,
        ...roomExtras,
        qty: 1
    }
    dispatch({
        type: types.ADD_ROOM_TO_CART,
        payload: cart
    })
    toast.success("Habitación agregada");
};

export const BookingSucces = () => (dispatch, getState) => {
    dispatch({
        type: types.BOOKING_SUCCESS,
        payload: {}
    })
    dispatch(changeCartSideBarStatus(false))
}

export const changeCartSideBarStatus = (status) => (dispatch) => {
    dispatch({
        type: types.CHANGE_CART_SIDEBAR_STATUS,
        payload: status
    })
}

export const changeBookingStatus = (status) => {
    return (dispatch) => {
        dispatch({
            type: types.BOOKING_PROCESSING,
            payload: status
        })
    }
}