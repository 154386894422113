import React from 'react'
import { Link } from 'react-router-dom'
import simg1 from '../../images/room/img-7.jpg'
import simg2 from '../../images/room/img-8.jpg'

import rv1 from '../../images/room/r1.jpg'
import rv2 from '../../images/room/r2.jpg'
import RoomSidebar from './RoomSidebar'

const RoomDetails = ({
    roomData,
    setActiveRoom,
    activeRoom,
    isPromo = false,
    promo
}) => {
    const SubmitHandler = (e) => {
        e.preventDefault()
    }

    const ClickHandler = () => {
        window.scrollTo(10, 0);
    }

    return (
        <div className="Room-details-area pb-120 pt-120">
            <div className="container">
                <div className="row">
                    <div className="col-lg-8 col-12">
                        <div className="room-description">
                            <div className="room-title">
                                <h2>Descripción</h2>
                            </div>
                            <div
                              dangerouslySetInnerHTML={{__html: `${isPromo ? promo.description : roomData.description}` }}
                            />
                            {isPromo 
                                ?   <p className='p-price'>Precio: {promo.price} Bs</p>
                                :   <p className='p-price'>Precio: {roomData.price} Bs<span> / hora</span></p>
                            }
                        </div>
                    </div>
                    <RoomSidebar
                        roomData = {roomData}
                        isPromo={isPromo}
                        setActiveRoom={setActiveRoom}
                        activeRoom={activeRoom}
                        promo={promo}
                    />
                </div>
            </div>
        </div>
    )
}

export default RoomDetails;