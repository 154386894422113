import axios from 'axios';
import React, { useState } from 'react'
import { toast } from 'react-toastify';
import SimpleReactValidator from 'simple-react-validator';
import Spinner from '../Spinner';


const ContactForm = () => {
    const [isSubmiting,setIsSubmiting] = useState(false)

    const [forms, setForms] = useState({
        name: '',
        email: '',
        phone: '',
        message: ''
    });
    const [validator] = useState(new SimpleReactValidator({
        className: 'errorMessage',
        validators: {
            isNotEmpty: {
                required: true,
                message: 'Campo obligatorio.',
                rule: (val, params, validatorP) => !!(val && val.length > 0),
            }
        }
    }));
    const changeHandler = e => {
        setForms({ ...forms, [e.target.name]: e.target.value })
        if (validator.allValid()) {
            validator.hideMessages();
        } else {
            validator.showMessages();
        }
    };

    const submitHandler = async (e) => {
        e.preventDefault();
        if (validator.allValid()) {
            validator.hideMessages();
            setIsSubmiting(true)
            await axios.post(`${process.env.REACT_APP_API_URL}api/v1/message`, forms)
            .then(function (response) {
                toast.success('Mensaje Envíado correctamente')
                setIsSubmiting(false)
            })
            .catch(function (error) {
                console.log(error);
                toast.error('Hubo un problema envíando su mensaje')
                setIsSubmiting(false)
            });
            setForms({
                name: '',
                email: '',
                phone: '',
                message: ''
            })
        } else {
            validator.showMessages();
        }
    };

    return (
        <form onSubmit={(e) => submitHandler(e)} className="contact-validation-active" >
            <div className="row">
                <div className="col col-lg-6 col-12">
                    <div className="form-field">
                        <input
                            value={forms.name}
                            type="text"
                            name="name"
                            onBlur={(e) => changeHandler(e)}
                            onChange={(e) => changeHandler(e)}
                            placeholder="Nombre completo" />
                        {validator.message('name', forms.name, 'isNotEmpty')}
                    </div>
                </div>
                <div className="col col-lg-6 col-12">
                    <div className="form-field">
                        <input
                            value={forms.email}
                            type="email"
                            name="email"
                            onBlur={(e) => changeHandler(e)}
                            onChange={(e) => changeHandler(e)}
                            placeholder="Correo electrónico" />
                        {validator.message('email', forms.email, 'isNotEmpty')}
                    </div>
                </div>
                <div className="col col-lg-12 col-12">
                    <div className="form-field">
                        <input
                            value={forms.phone}
                            type="number"
                            name="phone"
                            onBlur={(e) => changeHandler(e)}
                            onChange={(e) => changeHandler(e)}
                            placeholder="Teléfono" />
                        {validator.message('phone', forms.phone, 'isNotEmpty')}
                    </div>
                </div>
                <div className="col col-lg-12 col-12">
                    <textarea
                        onBlur={(e) => changeHandler(e)}
                        onChange={(e) => changeHandler(e)}
                        value={forms.message}
                        type="text"
                        name="message"
                        placeholder="Mensaje">
                    </textarea>
                    {validator.message('message', forms.message, 'isNotEmpty', {

                    })}
                </div>
            </div>
            <div className="submit-area">
                <button type="submit" disabled={isSubmiting} className="theme-btn"><div>{isSubmiting && <Spinner/>} Enviar</div></button>
            </div>
        </form >
    )
}

export default ContactForm;