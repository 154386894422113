import React from 'react'
import { Link } from 'react-router-dom'
import { WhatssAppIcon } from '../icons/Whatsapp';

const HeaderTopbar = () => {
    return (
        <div className="topbar">
            <div className="container-fluid">
                <div className="row">
                    <div className="col col-lg-7 col-md-8 col-sm-12 col-12">
                        <div className="contact-intro">
                            <ul>
                                <li><i className="fi flaticon-email"></i>reservas@motel-essence.com</li>
                                <li><i className="fi flaticon-phone-call"></i> +591 77984205</li>
                            </ul>
                        </div>
                    </div>
                    <div className="col col-lg-5 col-md-4 col-sm-12 col-12">
                        <div className="contact-info">
                            <ul>
                                <li><a target={'_blank'} rel='noreferrer' href="https://wa.link/0igtz5"><WhatssAppIcon marginBottom='0px' marginTop='-2px'/></a></li>
                                <li><a target={'_blank'} rel='noreferrer' href="https://www.facebook.com/essencecbba"><i className="fi flaticon-facebook-app-symbol"></i></a></li>
                                <li><a target={'_blank'} rel='noreferrer' href="https://www.instagram.com/motel.essence/"><i className="fi flaticon-instagram"></i></a></li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default HeaderTopbar;