export const formatDate = (date) => {
    const requiredDate = new Date(date)
    const yyyy = requiredDate.getFullYear()
    let mm = requiredDate.getMonth() + 1 
    let dd = requiredDate.getDate()

    if (dd < 10) dd = '0' + dd
    if (mm < 10) mm = '0' + mm

    const formatted = dd + '/' + mm + '/' + yyyy
    return formatted
}
export const getCurrentHour = (addHours = 0) => {
    const today = new Date()
    let hour = today.getHours() + addHours
    let mins = today.getMinutes()

    if (hour < 10) hour = '0' + hour
    if (mins < 10) mins = '0' + mins

    const hourFormated = hour + ':' + mins + ':00'
    return hourFormated
}