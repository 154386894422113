import React, {Fragment} from 'react';
import About from '../../components/about';
import BlogSection from '../../components/BlogSection';
import Destination from '../../components/Destination';
import Footer from '../../components/footer';
import FunFact from '../../components/FunFact';
import Hero from '../../components/hero';
import Navbar from '../../components/Navbar'
import Newslatter from '../../components/Newslatter/Newslatter';
import PricingSection from '../../components/PricingSection';
import Rooms from '../../components/Rooms/Rooms';
import Scrollbar from '../../components/scrollbar'
import SearchSection from '../../components/SearchSection';
import ServiceSection from '../../components/ServiceSection';
import Testimonial from '../../components/Testimonial';
import Logo from '../../images/logo.png'
import ServiceSection2 from '../../components/ServiceSection2/ServiceSection2';
import { Helmet } from 'react-helmet';
import Promos from '../../components/Promos';



const HomePage =() => {

    return(
        <Fragment>
            <Helmet>
                <title>Motel Essence | Cochabamba</title>
                <meta
                  name="description"
                  content="Ven y disfruta de momentos inolvidables con tu pareja en uno de los mejores moteles de cochabamba y vive la experiencia de nuestras habitaciones temáticas"
                />
                <meta name="keywords" content="cochabamba, alojamiento, motel" />
                <meta name="robots" content="index, follow"/>
                <meta name="googlebot" content="index, follow"/>
                <link rel="canonical" href={`${process.env.REACT_APP_FRONTENDURL}`} />
                <meta property="og:title" content="Motel Essence | Cochabamba" />
                <meta property="og:description" content="Ven y disfruta de momentos inolvidables con tu pareja en uno de los mejores moteles de cochabamba y vive la experiencia de nuestras habitaciones temáticas" />
                <meta property="og:url" content={`${process.env.REACT_APP_FRONTENDURL}`} />
            </Helmet>
            <Navbar topbarBlock={'wpo-header-style-2'} Logo={Logo}/> 
            <Hero/> 
            {/* <SearchSection/>  */}
            <Promos/>
            <Rooms/>  
            <ServiceSection2/> 
            <Newslatter/> 
            <Footer/> 
            <Scrollbar/>
        </Fragment>
    )
};

export default HomePage;