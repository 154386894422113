import React, { useEffect, useState } from 'react'
import { connect, useDispatch } from "react-redux";
import MobileMenu from "../MobileMenu";
import { removeFromCart } from "../../store/actions/action";
import { Link, useNavigate } from 'react-router-dom'
import HeaderTopbar from "../HeaderTopbar";
import shape from "../../images/hotel.png"
import { toast } from 'react-toastify';
import { formatDate } from '../../utils/dateTransformer';
import { BookingSucces, changeCartSideBarStatus } from '../../store/actions/cart';
import Spinner from '../Spinner';

const Header = (props) => {
  const navigate = useNavigate()

  const [cartActive, setcartState] = useState(false);
  const [clientForm, setClientForm] = useState({
    name: '',
    number: ''
  })

  const [isSubmiting, setIsSubmiting] = useState(false)

  const dispatch = useDispatch()

  const { carts, isOpenSideBar } = props;

  useEffect(() => {
    setcartState(isOpenSideBar)
  }, [carts, isOpenSideBar])

  const onCloseSidebar = () => {
    setcartState(!cartActive)
    dispatch(changeCartSideBarStatus(!cartActive))
  }

  const onViewRooms = () => {
    navigate('/habitaciones')
    onCloseSidebar()
  }

  const onViewCartRoom = ({id, isPromo, promoId}) => {
    if (id === '') return
    if (isPromo) navigate(`/promos/${promoId}`)
    else navigate(`/habitaciones/${id}`)
    navigate(0)
    window.scrollTo(10, 0);
    onCloseSidebar()
  }

  const onChangeForm = (event) => {
    const {
      name: inputName,
      value: inputValue
    } = event.target

    setClientForm({
      ...clientForm,
      [inputName]: inputValue
    })
  }

  const SubmitHandler =  async (e) => {
      e.preventDefault()
      if (!carts.id) {
        toast.error('Debe agregar una habitación')
        return
      }
      if (clientForm.name === '' || clientForm.number === ''){
        toast.error('Debe llenar sus datos')
        return
      }

      const bookingDate = formatDate(carts.date)
      const body = {
        clientName: clientForm.name,
        clientNumber: clientForm.number,
        roomName: carts.title,
        totalHours: carts.totalHours,
        totalPrice: carts.totalPrice,
        bookingDate,
        bookingHour: carts.hourReservation,
        isWithJacuzzi: carts.jacuzzi,
        isPromo: carts.isPromo,
        promoName: carts.promoName
      }
      try {
        setIsSubmiting(true)
        const URL = process.env.REACT_APP_API_URL
        const END_POINT = 'api/v1/booking'
        const rawResponse = await fetch(`${URL}${END_POINT}`, {
          method: 'POST',
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
          },
          body: JSON.stringify(body)
        });
        const response = await rawResponse.json()
        setIsSubmiting(false)
        toast.success(response.message)
        dispatch(BookingSucces())
        navigate('/gracias')
      } catch (error) {
        toast.error('Algo sucedio mal, intentelo más tarde')
        setIsSubmiting(false)
      }
  }

  const ClickHandler = () => {
      window.scrollTo(10, 0);
  }

    return (
      <header id="header" className={props.topbarBlock}>
        <HeaderTopbar />
        <div className={`wpo-site-header ${props.hclass}`}>
          <nav className="navigation navbar navbar-expand-lg navbar-light">
            <div className="container-fluid">
              <div className="row align-items-center">
                <div className="col-lg-3 col-md-3 col-3 d-lg-none dl-block">
                  <div className="mobail-menu">
                    <MobileMenu />
                  </div>
                </div>
                <div className="col-lg-2 col-md-6 col-6">
                  <div className="navbar-header">
                    <Link onClick={ClickHandler} className="navbar-brand logo" to="/">
                      <img style={{ maxHeight: '75px' }} src={props.Logo} alt="motel essence" />
                    </Link>
                  </div>
                </div>
                <div className="col-lg-9 col-md-1 col-1">
                  <div id="navbar" className="collapse navbar-collapse navigation-holder">
                    <button className="menu-close"><i className="ti-close"></i></button>
                    <ul className="nav navbar-nav mb-2 mb-lg-0">
                      <li><Link onClick={ClickHandler} to="/">Inicio</Link></li>
                      <li className="menu-item-has-children">
                        <Link onClick={ClickHandler} to="/habitaciones">Habitaciones</Link>
                      </li>
                      <li className="menu-item-has-children">
                        <Link onClick={ClickHandler} to="/promociones">Promociones</Link>
                      </li>
                      <li>
                        <Link onClick={ClickHandler} to="/contacto">Contacto</Link>
                      </li>
                    </ul>

                  </div>
                </div>
                <div className="col-lg-1 col-md-2 col-2">
                  <div className="header-right">
                    <div className="mini-cart">
                      <button className="cart-toggle-btn" onClick={onCloseSidebar}>
                        {" "}
                        <i className="fi flaticon-shopping-cart"></i>{" "}
                        <span className="cart-count">{carts?.id ? 1 : 0}</span>
                      </button>
                      <div className={`mini-cart-content ${cartActive ? "mini-cart-content-toggle" : ""}`}>
                        <button className="mini-cart-close" onClick={onCloseSidebar}><i className="ti-close"></i></button>
                        <div className="mini-cart-items" onClick={() => onViewCartRoom({id: carts?.id || '', isPromo: carts.isPromo, promoId: carts.promoId})}>
                          {carts.id ?
                              <div className="mini-cart-item clearfix">
                                <div className="mini-cart-item-image">
                                  <span>
                                    <img src={carts.proImg} alt="icon" />
                                  </span>
                                </div>
                                <div className="mini-cart-item-des">
                                  <p>{carts.title} </p>
                                  {carts.isPromo &&
                                    <span className="mini-cart-item-price">
                                      Promoción: {carts.promoName}
                                    </span>
                                  }
                                  <span className="mini-cart-item-price">
                                    Fecha: {formatDate(carts.date)}
                                  </span>
                                  <span className="mini-cart-item-price">
                                    A Horas: {carts.hourReservation}
                                  </span>
                                  <span className="mini-cart-item-price">
                                    Tiempo: {carts.totalHours} Hora(s)
                                  </span>
                                  <span className="mini-cart-item-price">
                                    jacuzzi: {carts.jacuzzi ? 'Si' : ' No'} 
                                  </span>
                                  <span className="mini-cart-item-quantity">
                                    <button
                                      onClick={(e) => {
                                        props.removeFromCart(carts.id);
                                        e.stopPropagation();
                                      }}
                                      className="btn btn-sm btn-danger"
                                    >
                                      <i className="ti-close"></i>
                                    </button>{" "}
                                  </span>
                                </div>
                              </div>
                              : <div className='mini-cart-no-item'>
                                <p>No tiene agregado ninguna habitación</p>
                                <span onClick={onViewRooms}>Ver habitaciones</span>
                              </div>
                            }
                        </div>
                        <div className='room-sidebar'>
                          <div className="widget check-widget">
                            <div className="input-group date">
                              <span>Nombre Completo:</span>
                              <input value={clientForm.name} name={'name'} onChange={onChangeForm} required={true}/>
                            </div>
                            <div className="input-group date">
                              <span>Celular:</span>
                              <input type='number' name='number' value={clientForm.number} onChange={onChangeForm} required={true}/>
                            </div>
                          </div>

                        </div>
                        <div className="mini-cart-action clearfix">
                          <span className="mini-checkout-price">Subtotal: <span>{carts.totalPrice ? carts.totalPrice : '0'} Bs</span></span>
                          <div className="mini-btn">
                            <button
                              onClick={SubmitHandler}
                              type='submit'
                              className='reseve-cart-btn'
                              disabled={isSubmiting}
                            >
                              {isSubmiting && <Spinner/>} Reservar
                            </button>
                          </div>
                        </div>
                        <div className="visible-icon">
                          <img src={shape} alt="icon"/>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </nav>
        </div>
      </header>
    )
  }

const mapStateToProps = (state) => {
  return {
    carts: state.cartList.cart,
    isOpenSideBar: state.cartList.isOpenSideBar,
  };
};
export default connect(mapStateToProps, { removeFromCart })(Header);

