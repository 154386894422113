import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { connect, useDispatch } from 'react-redux';
import api from '../../api';
import blogs from '../../api/blogs';
import {
  addRoomToCart,
  changeCartSideBarStatus,
} from '../../store/actions/cart';
import { getCurrentHour } from '../../utils/dateTransformer';
import { toast } from 'react-toastify';

const RoomSidebar = ({
  carts,
  roomData,
  setActiveRoom: setactiveRoom,
  activeRoom,
  isPromo = false,
  promo,
}) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const weekendSchedule = {
    min: '08:00',
    max: '20:00',
  };

  const weekSchedule = {
    min: '00:00',
    max: '23:00',
  };

  const filterWeekdays = (date) => {
    const day = date.getDay();
    if (promo?.excludeDays) return !promo.excludeDays.includes(day);
    return true;
  };

  const [roomPrices, setRoomPrices] = useState([]);
  // const [activeRoom, setactiveRoom] = useState(null);
  const [startDate, setStartDate] = useState(new Date());
  const [maxMinHours, setMaxMinHours] = useState(null);
  const [startHour, setStartHour] = useState(null);
  const [roomHours, setRoomHours] = useState(1);
  const [roomPrice, setRoomPrice] = useState(0);
  const [jacuzzi, setJacuzzi] = useState(false);

  const [maxDate, setMaxDate] = useState(null);
  const [minDate, setMinDate] = useState(null);

  useEffect(() => {
    // const today = new Date().toISOString().split('T')[0];
    const today = new Date();
    const day = today.getDate();
    const month = today.getMonth() + 1;
    const year = today.getFullYear();
    const minDate = `${month}/${day < 10 ? `0${day}` : `${day}`}/${year}`;
    setMinDate(minDate);
    const maxDate = `${month}/${day < 10 ? `0${day}` : `${day}`}/${year + 10}`;
    setMaxDate(maxDate);
  }, []);

  useEffect(() => {
    if (promo && promo?.excludeDays) {
      const today = new Date();
      if (promo.excludeDays.includes(today.getDay())) {
        setStartDate(null);
      }
    }
    if (promo && promo.jacuzzi) {
      setJacuzzi(true);
    }
  }, [promo]);

  useEffect(() => {
    const prices = createPrices();
    setRoomPrices(prices);
    const positon = prices.map((pric) => pric.time).indexOf(roomHours);
    if (promo) {
      setRoomPrice(parseInt(promo.price));
      setRoomHours(promo.hours);
      return;
    }
    if (positon !== -1) {
      setRoomPrice(prices[positon].price);
      setRoomHours(prices[positon].time);
    }
  }, [jacuzzi]);

  useEffect(() => {
    if (isPromo) {
      setRoomHours(promo.hours);
      setStartHour(promo.hourInit || getCurrentHour(1));
      setRoomPrice(parseInt(promo.price));
      setactiveRoom(roomData?.id);
      setMaxMinHours({
        min: promo.minHour || '',
        max: promo.maxHour || '',
      });
    }
  }, [promo]);

  useEffect(() => {
    if (Object.keys(carts).length === 0) return;
    const {
      id,
      date,
      hourReservation,
      totalHours,
      totalPrice,
      jacuzzi,
      promoId,
      isPromo: cartIsPromo,
    } = carts;
    if (cartIsPromo && !(promo?.id === promoId)) {
      setactiveRoom(roomData?.id);
      return;
    }
    if (id !== roomData?.id && !isPromo) {
      setactiveRoom(roomData?.id);
      return;
    }
    if (date) setStartDate(new Date(date));
    if (!!totalPrice) setRoomPrice(totalPrice);
    if (hourReservation && (!isPromo || !promo?.hourInit))
      setStartHour(hourReservation);
    if (!isPromo) setRoomHours(totalHours);
    setJacuzzi(jacuzzi);
    setactiveRoom(id);
  }, [promo]);

  const SubmitHandler = (e) => {
    e.preventDefault();
    if (isPromo && maxMinHours?.min && maxMinHours?.max) {
      const reservedHour = startHour.split(':')[0];
      if (
        reservedHour < maxMinHours.min.split(':')[0] ||
        reservedHour > maxMinHours.max.split(':')[0]
      ) {
        toast.error("Fuera de hora");
        return
      }
    }
    const data = {
      roomId: activeRoom,
      promoId: promo?.id,
      date: startDate,
      hourReservation: startHour,
      totalHours: roomHours,
      totalPrice: roomPrice,
      jacuzzi,
      isPromo,
      promoName: promo?.title,
    };
    dispatch(addRoomToCart(data));
    dispatch(changeCartSideBarStatus(true));
  };
  const onChangeDate = (date) => {
    const updatedDate = new Date(date);
    // if (promo?.maxHour || promo?.minHour) return
    setStartDate(date);
    if (updatedDate.getDay() === 0) setMaxMinHours(weekendSchedule);
    else setMaxMinHours(weekSchedule);
  };

  const onChangeInitHour = (event) => {
    setStartHour(event.target.value);
  };

  const onChangeHours = (event) => {
    const hour = event.target.value;
    const prices = createPrices();
    setRoomPrice(prices[hour - 1].price);
    setRoomHours(parseInt(hour));
  };

  const onChangeRoom = (event) => {
    if (isPromo) {
      setactiveRoom(parseInt(event.target.value));
      return;
    }
    const roomId = event.target.value;
    navigate(`/habitaciones/${roomId}`);
    navigate(0);
    window.scrollTo(10, 0);
  };

  const calculatePrice = (
    pricesPerHour,
    pricePerExtraHour,
    hours,
    jacuzziPrice,
  ) => {
    if (hours === 0) return 0;

    let completeHours = 0;
    let extraHours = 0;

    if (hours % 2 === 0) {
      completeHours = hours > 1 ? parseInt(hours / 2) : hours;
      extraHours = hours > 1 ? parseInt(hours / 2) : 0;
    } else {
      completeHours = hours > 1 ? parseInt((hours + 1) / 2) : hours;
      extraHours = hours > 1 ? parseInt((hours - 1) / 2) : 0;
    }

    return (
      completeHours * pricesPerHour +
      extraHours * pricePerExtraHour +
      (jacuzzi ? jacuzziPrice : 0)
    );
  };

  const createPrices = () => {
    const roomPrice = parseInt(roomData?.price);
    const extraPerHour = parseInt(roomData?.extra);
    const jacuzziPrice = parseInt(roomData?.jacuzzi);

    const prices = [...Array(10).keys()].map((_, idx) => {
      const price = calculatePrice(
        roomPrice,
        extraPerHour,
        idx + 1,
        jacuzziPrice,
      );
      return {
        id: idx,
        price,
        time: idx + 1,
        label: isPromo
          ? `${idx + 1} ${idx + 1 > 1 ? 'horas' : 'hora'}`
          : `${idx + 1} ${idx + 1 > 1 ? 'horas' : 'hora'} - ${price} Bs`,
      };
    });

    return prices;
  };

  const productsArray = api();
  const products = productsArray;
  return (
    <div className="col-lg-4 col-12">
      <div className="blog-sidebar room-sidebar">
        <div className="widget check-widget">
          <h3>Reservar Habitación</h3>
          <form onSubmit={SubmitHandler}>
            <div className="input-group date">
              <span>Habitación</span>
              <select onChange={onChangeRoom} name="Rooms" id="Rooms">
                {products.map((room) => (
                  <option value={room.id} selected={room.id === roomData?.id}>
                    {room.title}
                  </option>
                ))}
              </select>
            </div>
            <div className="input-group date">
              <span>Fecha</span>
              <DatePicker
                filterDate={filterWeekdays}
                minDate={new Date(minDate)}
                maxDate={new Date(maxDate)}
                selected={startDate}
                required={true}
                onChange={(date) => onChangeDate(date)}
              />
              <i className="fi flaticon-calendar"></i>
            </div>
            <div className="input-group date">
              <span>Hora de reserva</span>
              <input
                value={startHour}
                type="time"
                onChange={onChangeInitHour}
                max={maxMinHours?.max}
                min={maxMinHours?.min}
                required={true}
                disabled={isPromo && promo.hourInit}
              />
            </div>
            {!promo?.hideTimer && (
              <div className="input-group date">
                <span>Tiempo</span>
                <select onChange={onChangeHours} disabled={isPromo}>
                  {roomPrices.map((price) => (
                    <option
                      value={price.time}
                      selected={price.time === roomHours}
                    >
                      {price.label}
                    </option>
                  ))}
                </select>
              </div>
            )}
            {(!isPromo || promo?.jacuzzi) && (
              <div className="checkbox">
                <input
                  type="checkbox"
                  onChange={(event) => setJacuzzi(event.target.checked)}
                  checked={jacuzzi}
                  disabled={promo?.jacuzzi}
                />
                <span>Con jacuzzi</span>
              </div>
            )}
            <div className="input-group date">
              <button className="theme-btn" type="submit">
                Reservar Habitación
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};
const mapStateToProps = (state) => {
  return {
    carts: state.cartList.cart,
  };
};

export default connect(mapStateToProps)(RoomSidebar);

// export default connect(mapStateToProps, { removeFromCart })(Header);
