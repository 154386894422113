import React, {Fragment} from 'react';
import PageTitle from '../../components/pagetitle/PageTitle';
import Navbar from '../../components/Navbar';
import Footer from '../../components/footer'
import Scrollbar from '../../components/scrollbar'
import Logo from '../../images/logo4.png'
import Newslatter from '../../components/Newslatter/Newslatter';
import ContactpageSec from '../../components/ContactpageSec/ContactpageSec';
import { Helmet } from 'react-helmet';


const ContactPage =() => {
    return(
        <Fragment>
            <Helmet>
                <title>Contacto | Motel Essence | Cochabamba</title>
                <meta
                  name="description"
                  content="Calle José María Santivañez esq. Daniel Campos, zona Sausalito. Cochabamba - Bolivia. reservas@motel-essence.com +591 77984205"
                />
                <meta name="keywords" content="cochabamba, alojamiento, motel" />
                <meta name="robots" content="index, follow"/>
                <meta name="googlebot" content="index, follow"/>
                <meta property="og:url" content={`${process.env.REACT_APP_FRONTENDURL}/contacto`} />
                <link rel="canonical" href={`${process.env.REACT_APP_FRONTENDURL}`} />
                <meta property="og:title" content="Contacto | Motel Essence" />
                <meta property="og:description" content="Calle José María Santivañez esq. Daniel Campos, zona Sausalito. Cochabamba - Bolivia. reservas@motel-essence.com +591 77984205" />
            </Helmet>
            <Navbar hclass={'wpo-header-style-3'} Logo={Logo} />
            <PageTitle pageTitle={'Contacto'} pagesub={'Contacto'} classBg="contact-bg"/> 
            <ContactpageSec/> 
            <Newslatter nClass={'section-bg'}/>
            <Footer/>
            <Scrollbar/>
        </Fragment>
    )
};

export default ContactPage;

