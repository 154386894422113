import axios from 'axios';
import React, { useState } from 'react';
import { toast } from 'react-toastify';
import Shape1 from '../../images/nshape1.png'
import Shape2 from '../../images/nshape2.png'
import Spinner from '../Spinner';

const Newslatter = (props) => {
    const [isSubmiting,setIsSubmiting] = useState(false)

    const SubmitHandler = async (e) => {
        e.preventDefault()
        setIsSubmiting(true)
        const email = e.target[0].value 
        try {
            await axios.post(`${process.env.REACT_APP_API_URL}api/v1/mail-list`, {email})
            .then(function (response) {
                toast.success('Mensaje Envíado correctamente')
            })
            .catch(function (error) {
                console.log(error);
                toast.error('Hubo un problema envíando su mensaje')
            });
            e.target[0].value = ''
            setIsSubmiting(false)
        } catch (error) {
            console.error(error)
            setIsSubmiting(false)
            toast.error('Hubo un problema envíando su mensaje')
        }
    }

    return (
        <section className={`wpo-newslatter-section section-padding ${props.nClass}`}>
            <div className="container">
                <div className="row justify-content-center">
                    <div className="col-xl-7">
                        <div className="wpo-section-title-s2"> 
                            <h2>Suscríbete Y Obtén Descuentos Especiales</h2>
                            <p>¿No quieres perderte nada? Suscríbete ahora y obtén precios especiales y recibe actualizaciones mensualmente.</p>
                        </div>
                    </div>
                </div>
                <div className="wpo-newsletter">
                    <div className="newsletter-form">
                        <form onSubmit={SubmitHandler}>
                            <input type="email" className="form-control"
                                placeholder="Ingresa tu correo electrónico..." required />
                            {}
                            <button disabled={isSubmiting} type="submit">{isSubmiting && <Spinner/>}<span>Suscríbete</span></button>
                        </form>
                    </div>
                </div>
            </div>
            <div className="n-shape">
                <img src={Shape1} alt=""/>
            </div>
            <div className="n-shape2">
                <img src={Shape2} alt=""/>
            </div>
        </section>
    )
}


export default Newslatter;