import React from 'react'
import { Link } from 'react-router-dom'

const PageTitle = (props) => {
    return (
        <div className={`wpo-breadcumb-area ${props.classBg || ''}`} style={{ backgroundPosition: `${props.heroPosition || 'center'}`, backgroundSize: 'cover', backgroundImage: `url('${props?.heroImage}')`}} >
            <div className="container">
                <div className="row">
                    <div className="col-12">
                        <div className="wpo-breadcumb-wrap">
                            <h1>{props.pageTitle}</h1>
                            <ul>
                                <li><Link to="/home">Inicio</Link></li>
                                <li><span>{props.pagesub}</span></li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default PageTitle;