import React from 'react';
import ContactForm from '../ContactFrom'


const ContactpageSec = () => {
    
    const onContactItemClick = (url) => {
        window.open(url, '_blank');
    }

    return(
        <section className="wpo-contact-pg-section section-padding">
            <div className="container">
                <div className="row">
                    <div className="col col-lg-10 offset-lg-1">
                        <div className="office-info">
                            <div className="row">
                                <div className="col col-xl-4 col-lg-6 col-md-6 col-12" onClick={() => onContactItemClick('https://goo.gl/maps/WA4v6Xfvm1f7e97M6')}>
                                    <div className="office-info-item">
                                        <div className="office-info-icon">
                                            <div className="icon">
                                                <i className="fi flaticon-maps-and-flags"></i>
                                            </div>
                                        </div>
                                        <div className="office-info-text">
                                            <h2>Dirección</h2>
                                            <p>Calle José María Santivañez esq. Daniel Campos, zona Sausalito</p>
                                        </div>
                                    </div>
                                </div> 
                                <div className="col col-xl-4 col-lg-6 col-md-6 col-12" onClick={() => onContactItemClick('mailto:reservas@motel-essence.com')}>
                                    <div className="office-info-item">
                                        <div className="office-info-icon">
                                            <div className="icon">
                                                <i className="fi flaticon-email"></i>
                                            </div>
                                        </div>
                                        <div className="office-info-text">
                                            <h2>Correo electrónico</h2>
                                            <p>reservas@motel-essence.com</p>
                                            <p style={{ color: "white"}}>d</p>
                                        </div>
                                    </div>
                                </div> 
                                <div className="col col-xl-4 col-lg-6 col-md-6 col-12" onClick={() => onContactItemClick('tel:77984205')}>
                                    <div className="office-info-item">
                                        <div className="office-info-icon">
                                            <div className="icon">
                                                <i className="fi flaticon-phone-call"></i>
                                            </div>
                                        </div>
                                        <div className="office-info-text">
                                            <h2>Teléfonos</h2>
                                            <p>+591 77984205</p>
                                            <p>+591 4394894</p>
                                        </div>
                                    </div>
                                </div> 
                            </div>
                        </div>
                        <div className="wpo-contact-title">
                            <h2>Escríbenos</h2>
                            <p>Y te responderemos lo antes posible.</p>
                        </div>
                        <div className="wpo-contact-form-area">
                            <ContactForm/>
                        </div>
                    </div>                
                </div>
            </div> 
            <section className="wpo-contact-map-section">
                <div className="wpo-contact-map">
                <iframe src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d15229.950615403714!2d-66.2122151!3d-17.3883697!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0xf4a23c14dab07a81!2sMotel%20essence!5e0!3m2!1ses-419!2sbo!4v1664065980374!5m2!1ses-419!2sbo" ></iframe>               
                 </div>
            </section>
        </section>
     )
        
}

export default ContactpageSec;
