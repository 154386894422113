import React from "react";
import { Link } from "react-router-dom";
import SectionTitleS2 from '../SectionTitleS2'

const SearchRooms = ({ products, addToCartProduct }) => {
  const ClickHandler = () => {
    window.scrollTo(10, 0);
  };

  return (
    <div className="wpo-room-area mt-5">
      <div className="container">
        {/* <div className="row align-items-center justify-content-center">
          <div className="col-xl-6 col-md-8">
            <SectionTitleS2 MainTitle={'Search Result'} />
          </div>
        </div> */}
        <div className="room-wrap">
          <div className="row">
            {products.length > 0 &&
              products.map((product, pitem) => (
                <div className={`${products.length < 3 ? 'col-lg-6' : 'col-lg-4'} col-md-6 col-12`} key={pitem}>
                  <div className="room-item">
                    <div className="room-img">
                      {product.isPromo
                        ? <img src={product.cover} alt="" />
                        : <img src={product.proImg} alt="" />
                      }
                    </div>
                    <div className="room-content">
                      <h2><Link onClick={ClickHandler} to={`/habitaciones/${product.id}`}>{product.title}</Link></h2>
                      {product.isPromo &&
                        <p>{product.shortDescription}</p>
                      }
                      {product.isPromo
                        ? <h3>{product.price} Bs</h3>
                        : <h3>{product.price} Bs <span>/ Hora</span></h3>
                      }
                      <div className="add-to-cart">
                        <button
                        className="theme-btn mt-3"
                          data-bs-toggle="tooltip"
                          data-bs-html="true"
                          title="Add to Cart"
                          onClick={() => addToCartProduct(product)}
                        >
                          Ver habitación
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default SearchRooms;
