import React from 'react'
import Services from '../../api/service'
import { Link } from 'react-router-dom'
import TvIcon from '../icons/Tv'
import PoleDanceTubeIcon from '../icons/PoleDanceTube'
import PlayOfLights from '../icons/PlayOfLights'
// import PlayOfLights from '../icons/PlayOfLights'


const ServiceSection2 = (props) => {
    const customIcons = [
        'Tv Cable',
        'Tubo de Baile',
        'Juego de luces'
    ]
    const ClickHandler = () => {
        window.scrollTo(10, 0);
    }    

    return (

        <div className="wpo-service-area section-padding">
            <div className="wpo-service-wrap">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-xl-4 col-lg-8 col-12">
                            <div className="wpo-service-content">
                                <h2>Ofrecemos los complementos y servicios perfectos para encantar en cada ocasión.</h2>
                                <p>Todas nuestras habitaciones estan equipadas para todo tipo de ocasión</p>
                            </div>
                        </div>
                        <div className="col-xl-8 col-12">
                            <div className="wpo-service-items">
                                {Services.map((service, sitem) => (
                                    <div className="wpo-service-item" key={sitem}>
                                        {service.title === 'Tv Cable'&& <TvIcon/>}
                                        {service.title === 'Tubo de Baile' && <PoleDanceTubeIcon/> }
                                        {service.title === 'Juego de luces' && <PlayOfLights/> }
                                        {
                                            !customIcons.includes(service.title) &&
                                                <i className={`fi ${service.fIcon}`}></i>
                                        }
                                        <Link to={`#`}>{service.title}</Link>
                                    </div>
                                ))}

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ServiceSection2;