import { Fragment, useEffect } from "react"
import Footer from "../../components/footer"
import Navbar from "../../components/Navbar"

import Scrollbar from "../../components/scrollbar"
import Logo from '../../images/logo4.png'


const ThanksPage =() => {
    useEffect(() => {
        window.scrollTo(10, 0);
    }, [])
    
    return(
        <Fragment>
            <Navbar hclass={'wpo-header-style-3'} Logo={Logo} />
            <div className="thanks-container">
                <h1 className="">Tu Reserva se Realizo de manera correcta</h1>
                <div className="content">
                    <p>Muchas gracias por la confianza presentada en nuestro equipo, te esperamos para que pasaes momento inolvidables</p>
                </div>
                <a href="/">
                    Seguir navegando
                </a>
            </div>
            <Footer/> 
            <Scrollbar/>
        </Fragment>
    )
};

export default ThanksPage