import React, {Fragment} from 'react';
import PageTitle from '../../components/pagetitle/PageTitle';
import { connect } from "react-redux";
import Navbar from '../../components/Navbar';
import { addToCart } from "../../store/actions/action";
import { useNavigate } from 'react-router-dom';


import SearchRooms from '../../components/SearchRooms/SearchRooms';
import api, { promosApi } from "../../api";
import Scrollbar from '../../components/scrollbar';
import Logo from '../../images/logo4.png'
import heroImage from '../../images/rooms_hero.jpg'
import Footer from '../../components/footer';
import { Helmet } from 'react-helmet';


const PromosPage =({ addToCart }) => {
    const navigate = useNavigate()

    const productsArray = promosApi();
    
    const addToCartProduct = (product, qty = 1) => {
        window.scrollTo(10, 0);
        navigate(`/promos/${product.id}`)
      };

    const products = productsArray

    return(
        <Fragment>
            <Helmet>
                <title>Promociones | Motel Essence</title>
                <meta
                  name="description"
                  content="¿Conoce nuestras habitaciones temáticas. Si aún no lo hiciste aún no dejes que nadie te cuente cómo se vive un momento Essence. Y si ya lo hiciste ¡repítelo!"
                />
                <meta name="keywords" content="cochabamba, alojamiento, motel" />
                <meta name="robots" content="index, follow"/>
                <meta name="googlebot" content="index, follow"/>
                <link rel="canonical" href={`${process.env.REACT_APP_FRONTENDURL}`} />
                <meta property="og:title" content="Habitaciones | Motel Essence" />
                <meta property="og:description" content="¿Conoce nuestras habitaciones temáticas. Si aún no lo hiciste aún no dejes que nadie te cuente cómo se vive un momento Essence. Y si ya lo hiciste ¡repítelo!" />
                <meta property="og:url" content={`${process.env.REACT_APP_FRONTENDURL}/habitaciones`} />
            </Helmet>
            <Navbar hclass={'wpo-header-style-3'} Logo={Logo}/>
            <PageTitle pageTitle={'Nuestras Promociones'} pagesub={'promociones'} heroImage={heroImage}/>
            <div className="row justify-content-center mt-4">
                    <div className="col-xl-7">
                        <div className="wpo-section-title-s2 mb-0"> 
                            <h2>Las mejores habitaciones temáticas a los mejores precios</h2>
                            <p>Tenemos las mejores ofertas para que no pares de compartir esos momenetos únicos y placenteros</p>
                        </div>
                    </div>
                </div>
              <section className="wpo-shop-page">
                  <div className="container">
                      <div className="row">
                          <div className="col-lg-12">
                              <SearchRooms
                                  addToCartProduct={addToCartProduct}
                                  products={products}
                                />
                          </div>
                      </div>
                  </div>
              </section>
              <Footer/>
            <Scrollbar/>
        </Fragment>
    )
};

export default connect(null, { addToCart })(PromosPage);